"use client";

import React from 'react';

const Button = ({ children, className, onClick }) => {
    return (
        <button
            className={`bg-[#B3BAA8] text-black px-6 py-2 text-sm lg:text-base hover:opacity-50 transition-opacity rounded-3xl ${className} 
            hover:scale-105 transition-transform duration-200 ease-in-out;`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
