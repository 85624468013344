"use client"
import { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa'; // Vous pouvez utiliser une icône de flèche

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Fonction pour faire défiler vers le haut
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Moniteur de défilement
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div className="fixed bottom-4 right-4">
            {isVisible && (
                <button
                    onClick={scrollToTop}
                    className="bg-black p-3 rounded-full shadow-lg hover:bg-[#F1B6B6] focus:outline-none"
                    aria-label="Scroll to top"
                >
                    <FaArrowUp className="text-white w-6 h-6" />
                </button>
            )}
        </div>
    );
};

export default ScrollToTopButton;
