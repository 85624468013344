"use client";
import React, { useState, useEffect, useRef } from 'react';
import { BsCart3, BsPerson, BsSearch } from 'react-icons/bs';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'; // Menu burger icons
import Image from 'next/image';
import Link from 'next/link';
import Button from '../components/button';

const Navbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef(null); // Référence pour le menu déroulant
    const searchInputRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Toggle mobile menu
    };

    const toggleSearch = () => {
        setSearchOpen(!searchOpen);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault(); // Empêche le rechargement de la page
        if (searchTerm) {
            // Redirige vers la page de résultats de recherche avec la requête
            window.location.href = `/search?query=${encodeURIComponent(searchTerm)}`;
        }
    };

    // Fonction pour gérer le clic en dehors du menu déroulant
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
        // Retirer cette partie pour ne pas fermer l'input de recherche
    };

    useEffect(() => {
        // Ajout de l'événement global pour détecter les clics en dehors du menu
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Nettoyage de l'événement lorsque le composant est démonté
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className="p-4 flex justify-between items-center relative">
            {/* Logo */}
            <div className="relative w-60 h-20 text-xl font-bold ml-10 md:ml-[250px] lg:ml-20 cursor-pointer transition-transform transform hover:scale-105">
                <Link href="/" aria-label="Retour à la page d'accueil">
                    <Image
                        src="/assets/logohormelys1.webp"
                        alt="Logo Hormelys"
                        fill   // Utilisez `fill` pour que l'image remplisse le conteneur
                        sizes="(max-width: 768px) 100vw, 200px"
                        style={{ objectFit: 'contain' }} // Gardez l'image à l'intérieur du conteneur sans la découper
                    />
                </Link>
            </div>

            {/* Menu Burger for Mobile */}
            <div className="lg:hidden flex items-center">
                {menuOpen ? (
                    <AiOutlineClose
                        className="text-2xl text-black cursor-pointer hover:text-[#B3BAA8]"
                        onClick={toggleMenu}
                    />
                ) : (
                    <AiOutlineMenu
                        className="text-2xl text-black cursor-pointer hover:text-[#B3BAA8]"
                        onClick={toggleMenu}
                    />
                )}
            </div>

            {/* Menu Links (desktop and mobile) */}
            <ul
                className={`${menuOpen ? 'block z-50 w-screen h-screen' : 'hidden'
                    } absolute md:absolute lg:relative bg-[#FFF8F4] lg:bg-transparent top-full left-0 w-full md:w-full lg:w-auto lg:flex lg:flex-row lg:items-center lg:space-x-8 space-y-4 lg:space-y-0 p-4 lg:p-0 lg:mt-0 transition-all duration-300 ease-in-out`}
            >
                {/* Menu déroulant Sphère d'action */}
                <li className="relative lg:static">
                    <button
                        onClick={toggleDropdown}
                        className="text-black text-sm md:text-base lg:text-lg leading-relaxed hover:text-[#B3BAA8] transition-transform transform hover:scale-105 focus:outline-none"
                    >
                        Sphère d&apos;action
                    </button>
                    <ul
                        ref={dropdownRef}
                        className={`transition-all duration-300 ease-in-out ${dropdownOpen ? 'block' : 'hidden'} space-y-2 mt-2 lg:mt-0 relative lg:absolute w-full lg:w-48`}
                    >
                        <li className="px-4 py-0 text-black hover:text-[#B3BAA8] cursor-pointer">
                            <Link href="/fertilite">
                                Fertilité
                            </Link>
                        </li>
                        <li className="px-4 py-0 text-black hover:text-[#B3BAA8] cursor-pointer">
                            <Link href="/troubles-hormonaux">
                                Troubles hormonaux
                            </Link>
                        </li>
                        <li className="px-4 py-0 text-black hover:text-[#B3BAA8] cursor-pointer">
                            <Link href="/perte-de-poids">
                                Perte de poids
                            </Link>
                        </li>
                    </ul>
                </li>
                <li className="text-black text-sm md:text-base lg:text-lg leading-relaxed hover:text-[#B3BAA8] transition-transform transform hover:scale-105">
                    <Link href="/accompagnement">
                        Accompagnement
                    </Link>
                </li>
                <li className="text-black text-sm md:text-base lg:text-lg leading-relaxed hover:text-[#B3BAA8] transition-transform transform hover:scale-105">
                    <Link href="/rendez-vous-et-tarifs">
                        Rendez-vous et tarifs
                    </Link>
                </li>
                <li className="text-black text-sm md:text-base lg:text-lg leading-relaxed hover:text-[#B3BAA8] transition-transform transform hover:scale-105">
                    <Link href="/articles">
                        Articles
                    </Link>
                </li>
                <li className="text-black text-sm md:text-base lg:text-lg leading-relaxed hover:text-[#B3BAA8] transition-transform transform hover:scale-105">
                    <Link href="/a-propos">
                        A propos
                    </Link>
                </li>
                <li className="block lg:hidden">
                    <div className="relative">
                        <BsSearch
                            className="text-xl text-black hover:text-[#B3BAA8] cursor-pointer"
                            onClick={toggleSearch}
                        />
                        {/* Search Input */}
                        {searchOpen && (
                            <form onSubmit={handleSearchSubmit}>
                                <input
                                    ref={searchInputRef}
                                    type="text"
                                    placeholder="Recherche..."
                                    className="mt-2 w-full border-b-2 border-[#B3BAA8] outline-none p-1"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </form>
                        )}
                    </div>
                </li>
            </ul>

            {/* Icônes et bouton (always visible) */}
            <div className="hidden md:hidden lg:flex space-x-4 items-center mr-10">
                <div className="relative">
                    <BsSearch
                        className="text-xl text-black hover:text-[#B3BAA8] cursor-pointer"
                        onClick={toggleSearch}
                    />
                    {/* Search Input */}
                    {searchOpen && (
                        <form onSubmit={handleSearchSubmit}>
                            <input
                                ref={searchInputRef}
                                type="text"
                                placeholder="Recherche..."
                                className="absolute top-full -left-40 mt-4 border-b-2 border-[#B3BAA8] outline-none p-1 w-[200px]"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </form>
                    )}
                </div>
                <Link href="/contactez-moi" aria-label="Pour me contacter ou prendre un rendez-vous">
                    <Button>
                        Contactez moi ou prenez rendez-vous
                    </Button>
                </Link>
            </div>
        </nav>
    );
};

export default Navbar;




