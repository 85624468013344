'use client';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '../components/button';

const LatestArticles = () => {
    const [latestArticles, setLatestArticles] = useState([]);

    useEffect(() => {
        const fetchLatestArticles = async () => {
            console.log("API URL:", process.env.NEXT_PUBLIC_BASE_URL);
            try {
                const res = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/api/articles?sort=date:desc&limit=3`);
                const articlesWithReadingTime = res.data.map(article => {
                    const wordCount = calculateWordCount(article.content);
                    const readingTime = Math.ceil(wordCount / 200); // 200 mots par minute
                    return {
                        ...article,
                        readingTime // Ajoute le temps de lecture aux attributs
                    };
                });
                setLatestArticles(articlesWithReadingTime);
            } catch (error) {
                console.error("Erreur lors de la récupération des derniers articles :", error);
            }
        };

        const calculateWordCount = (content) => {
            const text = content.replace(/<[^>]*>/g, ''); // Supprime les balises HTML
            return text.split(" ").filter(word => word.length > 0).length; // Compte les mots
        };

        fetchLatestArticles();
    }, []);

    const getBadgePosition = (category) => {
        switch (category) {
            case 'Fertilité':
                return {
                    position: 'absolute top-2 -right-5 md:top-2 lg:-right-5 transform rotate-45',
                    clipClass: 'clip-customFertilite'
                };
            case 'Perte de poids':
                return {
                    position: 'absolute top-6 -right-7 md:top-6 md:-right-7 lg:-right-7 transform rotate-45',
                    clipClass: 'clip-customWeightLoss'
                };
            case 'Troubles hormonaux':
                return {
                    position: 'absolute top-9 -right-8 md:top-9 md:-right-8 transform rotate-45',
                    clipClass: 'clip-customHormonal'
                };
            default:
                return {
                    position: 'absolute bottom-3 left-3',
                    clipClass: 'clip-customDefault' // Classe par défaut si nécessaire
                };
        }
    };

    const truncateDescription = (description) => {
        if (description.length > 250) {
            return `${description.substring(0, 250)}...`;
        }
        return description;
    };

    return (
        <div className="latest-articles-section overflow-y-auto h-screen md:h-auto">
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 p-4 md:p-0">
                {latestArticles.map((article) => {
                    console.log("TATA", article.imageUrl);
                    const { position, clipClass } = getBadgePosition(article.category);

                    return (
                        <div key={article._id} className="article-card bg-[#FEE8E8] shadow-md rounded-lg p-6 relative">
                            <div className="relative">
                                {article.imageUrl && (
                                    <Image
                                        src={article.imageUrl}
                                        alt={article.title}
                                        className="rounded-t-lg w-full h-auto"
                                        width={800} // Remplacez par la largeur souhaitée
                                        height={600} // Remplacez par la hauteur souhaitée
                                        layout="responsive" // Ajuste l'image pour maintenir le rapport d'aspect
                                    />
                                )}
                                <div className={`${position} ${clipClass} 
                                px-4 py-1 text-xs font-semibold text-white bg-gradient-to-r from-pink-600 to-pink-400 
                                shadow-lg transform transition-transform duration-300 hover:rotate-0 hover:scale-105`}>
                                    {article.category}
                                </div>
                            </div>

                            <p className="text-sm text-gray-600 mt-1">Temps de lecture : {article.readingTime} min | Publié le : {new Date(article.date).toLocaleDateString()}</p>
                            <h3 className="text-xl font-semibold mt-4">{article.title}</h3>
                            <p className="mt-2">{truncateDescription(article.description)}</p>

                            <div className="mt-4 flex justify-center">
                                <Button
                                    onClick={() => window.location.href = `/articles/${article._id}`}
                                    aria-label={`Lire l'article complet : ${article.title}`}
                                >
                                    Lire l&apos;article complet
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default LatestArticles;


