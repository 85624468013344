"use client";

import React from 'react';

const ButtonRose = ({ children, className, onClick }) => {
    return (
        <button
            className={`bg-[#FFF8F4] text-black px-6 py-2 text-sm lg:text-base hover:opacity-50 transition-opacity rounded-3xl ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default ButtonRose;
