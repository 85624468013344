"use client"

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const GoogleReviews = () => {
    const [reviews, setReviews] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/api/reviews/google-reviews`)
            .then((response) => setReviews(response.data.reviews))
            .catch((err) => setError(err.message));
    }, []);

    if (error) {
        return <div className="text-red-600 text-center mt-10">Erreur lors de la récupération des avis : {error}</div>;
    }

    return (
        <div className="bg-white shadow-lg rounded-2xl p-8 mx-4 lg:mx-20 mt-20 mb-10">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
                Avis <span className="text-[#4285F4]">G</span><span className="text-[#EA4335]">o</span><span className="text-[#FBBC05]">o</span><span className="text-[#4285F4]">g</span><span className="text-[#34A853]">l</span><span className="text-[#EA4335]">e</span>
            </h2>
            {reviews.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {reviews.map((review, index) => (
                        <div
                            key={index}
                            className="bg-gradient-to-br from-gray-50 to-gray-100 border border-gray-200 rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow duration-300"
                        >
                            <div className="flex items-center gap-4 mb-4">
                                <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
                                    <span className="text-gray-600 font-bold text-xl">
                                        {review.authorName[0]}
                                    </span>
                                </div>
                                <div>
                                    <p className="text-lg font-semibold text-gray-800">{review.authorName}</p>
                                    <p className="text-sm text-gray-500">{review.relativeTime}</p>
                                </div>
                            </div>
                            <p className="text-yellow-500 text-lg font-semibold mb-4">
                                {'★'.repeat(review.rating)}{' '}
                                {'☆'.repeat(5 - review.rating)}
                            </p>
                            <p className="text-gray-700 text-sm leading-relaxed">{review.text}</p>
                        </div>
                    ))}
                </div>
            ) : (
                    <p className="text-center text-gray-500">Aucun avis pour l&apos;instant.</p>
            )}
        </div>
    );
};

export default GoogleReviews;





